
import { FacilityConfigAttributeEntityModel, FacilityConfigAttributeQueryModel } from '@common-src/entity-model/facility-config-attribute-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${BI_BASE_REQUEST_PATH}/statsType`;

class FacilityConfigTypeAttributeService implements ICRUDQ<FacilityConfigAttributeEntityModel, FacilityConfigAttributeQueryModel> {
    async create(model: FacilityConfigAttributeEntityModel):Promise<FacilityConfigAttributeEntityModel> {
        const url = `${URL_PATH}/saveAttr`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<FacilityConfigAttributeEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return res;
        // return new FacilityConfigAttributeEntityModel().toModel(res);
    }

    async update(model: FacilityConfigAttributeEntityModel):Promise<FacilityConfigAttributeEntityModel> {
        const url = `${URL_PATH}/saveAttr`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: FacilityConfigAttributeEntityModel):Promise<FacilityConfigAttributeEntityModel> {
        const url = `${URL_PATH}/deleteAttr/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: FacilityConfigAttributeQueryModel):Promise<any> {
        const url = `${URL_PATH}/getAttrs/${query.entityId}`;
        const res = await get(url);
        // 不带分页功能的处理方法
        return _.map(res, item => item = new FacilityConfigAttributeEntityModel(true, query.entityId).toModel(item));
    }
}

export default new FacilityConfigTypeAttributeService();
